<template>
    <div>
        <b-modal size="xl" ref="modal" hide-footer>
            <template v-slot:modal-title>
                <template v-if="ajouter">
                    {{ $t("compta.ajouter_article") }}
                </template>

                <template v-if="modifier">
                    {{ $t("compta.modifier_article") }}
                </template>
            </template>

            <div v-if="!modal_loaded">
                <LoadingSpinner class="col-12" />
            </div>

            <div v-if="modal_loaded">
                <form @submit.prevent="checkForm">
                    <input type="hidden" id="articles_id" class="form-control" v-model="form.articles_id">
                    <div class="row">
                        <div class="col-12" v-if="form_message !== ''">
                            <ErrorAlert :messageI18n="form_message" />
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="articles_nom" class="col-form-label">{{ $t("compta.form.nom") }} *</label>
                                <input type="text" id="articles_nom" class="form-control" v-model="form.articles_label" required @blur="correctCode" :class="{ 'is-invalid': error && error.indexOf('Erreur nom') > -1 }">
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="articles_code" class="col-form-label">{{ $t("compta.form.code_article") }} *</label>
                                <input type="text" id="articles_code" class="form-control" v-model="form.articles_code" required :class="{ 'is-invalid': error && error.indexOf('Erreur code') > -1 }">
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="articles_tarif" class="col-form-label">{{ $t("compta.form.tarif_ht") }} *</label>
                                <!-- <PriceInput v-model="form.articles_ht" @change="setHtArticle(form.articles_ht)" :class="{ 'is-invalid': error && error.indexOf('Erreur tarif') > -1 }"/> -->
                                <input type="text" id="articles_tarif" class="form-control" v-model="form.articles_ht" required :class="{ 'is-invalid': error && error.indexOf('Erreur tarif') > -1 }">
                            </div>
                        </div>

                        <div class="col-6">
                             <div class="form-group">
                                <label for="articles_vat" class="col-form-label">{{ $t("compta.form.vat") }} *</label>
                                 <e-select
                                    v-model="form.articles_vat"
                                    id="articles_vat"
                                    track-by="vat_value"
                                    label="vat_label"
                                    :placeholder="labelTitleVat"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="vat"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur tva') > -1 }"
                                />
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="articles_commentaire" class="col-form-label">{{ $t("compta.form.commentaire_defaut") }}</label>
                                <textarea class="form-control" id="articles_commentaire" v-model="form.articles_commentaire"></textarea>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="articles_quantite" class="col-form-label">{{ $t("compta.form.quantite_defaut") }}</label>
                                <input type="number" id="articles_quantite" class="form-control" v-model="form.articles_quantite">
                            </div>
                        </div>

                        <div class="col-12 ">
                            <div class="form-group">
                                <label for="articles_articlestype" class="col-form-label">{{ $t("compta.form.articles_type") }} *</label>
                                 <e-select
                                    v-model="form.articles_articlestype"
                                    id="articles_articlestype"
                                    track-by="articlestype_id"
                                    label="articlestype_label"
                                    :placeholder="labelTitleArticlesType"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="articles_type"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur articles type') > -1 }"
                                />
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="articles_accountingaccount" class="col-form-label">{{ $t("compta.form.compte") }} *</label>
                                <e-select
                                    v-model="form.articles_accountingaccount"
                                    id="articles_accountingaccount"
                                    track-by="id"
                                    label="name"
                                    :placeholder="labelTitleCompteComptable"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="comptes_comptables"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable') > -1 }"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                                
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="articles_vataccount" class="col-form-label">{{ $t("compta.form.vat_compte_comptable") }} *</label>
                                <e-select
                                    v-model="form.articles_vataccount"
                                    id="articles_vataccount"
                                    track-by="id"
                                    label="name"
                                    :placeholder="labelTitleCompteComptable"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="comptes_comptables_vat"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable') > -1 }"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                                
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="articles_accountingaccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
                                <e-select
                                    v-model="form.articles_accountingaccountfdvxp"
                                    id="articles_accountingaccountfdvxp"
                                    track-by="id"
                                    label="name"
                                    :placeholder="labelTitleCompteComptable"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="comptes_comptables"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvxp') > -1 }"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                                
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="articles_vataccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
                                <e-select
                                    v-model="form.articles_vataccountfdvxp"
                                    id="articles_vataccountfdvxp"
                                    track-by="id"
                                    label="name"
                                    :placeholder="labelTitleCompteComptable"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="comptes_comptables_vat"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvxp') > -1 }"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="articles_accountingaccountfdvex" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
                                <e-select
                                    v-model="form.articles_accountingaccountfdvex"
                                    id="articles_accountingaccountfdvex"
                                    track-by="id"
                                    label="name"
                                    :placeholder="labelTitleCompteComptable"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="comptes_comptables"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvex') > -1 }"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                                
                            </div>
                        </div>

                        



                        

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="articles_vataccountfdvex" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
                                <e-select
                                    v-model="form.articles_vataccountfdvex"
                                    id="articles_vataccountfdvex"
                                    track-by="id"
                                    label="name"
                                    :placeholder="labelTitleCompteComptable"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="comptes_comptables_vat"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvex') > -1 }"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                            </div>
                        </div>
                    </div>

                    <b-form-checkbox
                        id="articles_doublons"
                        v-model="form.articles_doublons"
                        >
                        {{ $t('compta.form.articles_doublons') }}
                    </b-form-checkbox>

                </form>

                <b-button v-if="ajouter" class="mt-3" block @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier" class="mt-3" block @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t("global.modifier") }}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import Accounting from "@/mixins/Accounting.js"
    import Article from "@/mixins/Article.js"
    import Invoice from "@/mixins/Invoice.js"

    export default {
        name: "ArticleAjout",
        props: ['accountingplan_id', 'preselect_infos'],
        mixins: [Navigation, Accounting, Tools, Article, Invoice],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),

                comptes_comptables: [],
                comptes_comptables_vat: [],
                labelTitleCompteComptable: this.getTrad("compta.search_accounting_account"),
                labelTitleArticlesType: this.getTrad("compta.search_articles_type"),

                vat: [],
                error: [],
                articles_type: [],
                labelTitleVat: this.getTrad("compta.search_vat"),

                accountingplan_id_local: null,
                modal_loaded: false,
                ajouter: false,
                modifier: false,
                processing: false,
                form_message: "",
                form: {},
                default_form: {
                    articles_id: '',
                    articles_label: '',
                    articles_ht: '',
                    articles_code: '',
                    articles_quantite: 1,
                    articles_commentaire: '',
                    articles_doublons: true,
                    articles_vat: {},
                    articles_accountingaccount: {},
                    articles_accountingaccountfdvex: {},
                    articles_accountingaccountfdvxp: {},
                    articles_articlestype: {
                        articlestype_label: this.getTrad('compta.articles_type.service'),
                        articlestype_id: 1
                    },
                    articles_vataccount: {},
                    articles_vataccountfdvex: {},
                    articles_vataccountfdvxp: {},
                },
                events_tab: {
                    'TableAction::goToAddArticle': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditArticle': (params) => {
                        this.addOrEdit(params.articles_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {

                this.accountingplan_id_local = this.accountingplan_id
                if(Object.keys(this.comptes_comptables).length == 0) {
                    let comptes_comptables = await this.loadAccountingAccounts(this.accountingplan_id_local)
                    comptes_comptables = comptes_comptables.filter(cc => !cc.accountingaccount_supplier)

                    if(comptes_comptables) {
                        for(let i = 0; i < comptes_comptables.length; i++) {
                            // Camille 09/09 => il n’y a que les comptes de TVA qu’il faut “bloquer” du coup
							if(comptes_comptables[i].accountingaccount_vat == null) {
								this.comptes_comptables.push({
                                    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
                                    id: comptes_comptables[i].accountingaccount_id 
                                })
							}
                            else
                            {
                                this.comptes_comptables_vat.push({
                                    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
                                    id: comptes_comptables[i].accountingaccount_id,
                                    vat: comptes_comptables[i].accountingaccount_vat
                                })
                            }
                        }
                    }
                }

                if(this.vat.length == 0) {
                    this.vat = await this.getVat()
                }

                if(this.articles_type.length == 0) {
                    let articles_type = await this.getArticlesType()
                    for (let i = 0; i < articles_type.length; i++) {
                        this.articles_type.push({
                            articlestype_id: articles_type[i].articlestype_id,
                            articlestype_label: this.getTrad(articles_type[i].articlestype_label),
                            articlestype_code: articles_type[i].articlestype_code,
                        })
                    }
                }
            },
            async addOrEdit(articles_id) {
                this.form_message = ""
                this.modal_loaded = false
                this.$refs['modal'].show()

                if(articles_id) {
                    this.form = this.deppCloneObj(this.$parent.articles.filter(article => article.articles_id == articles_id)[0])

                    if(this.form.vat) {
                        this.form.articles_vat = {
							vat_id: this.form.vat.vat_id,
							vat_label: this.form.vat.vat_label,
						}
                    }

                    if(this.form.accounting_account) {
                        this.form.articles_accountingaccount = {
                            name: this.form.accounting_account.accountingaccount_number + " ("+ this.form.accounting_account.accountingaccount_label +")", 
                            id: this.form.accounting_account.accountingaccount_id
                        }
                    }

                    if(this.form.accounting_account_fdvex) {
                        this.form.articles_accountingaccountfdvex = {
                            name: this.form.accounting_account_fdvex.accountingaccount_number + " ("+ this.form.accounting_account_fdvex.accountingaccount_label +")", 
                            id: this.form.accounting_account_fdvex.accountingaccount_id
                        }
                    }

                    if(this.form.accounting_account_fdvxp) {
                        this.form.articles_accountingaccountfdvxp = {
                            name: this.form.accounting_account_fdvxp.accountingaccount_number + " ("+ this.form.accounting_account_fdvxp.accountingaccount_label +")", 
                            id: this.form.accounting_account_fdvxp.accountingaccount_id
                        }
                    }

                    if(this.form.vat_account) {
                        this.form.articles_vataccount = {
                            name: this.form.vat_account.accountingaccount_number + " ("+ this.form.vat_account.accountingaccount_label +")", 
                            id: this.form.vat_account.accountingaccount_id
                        }
                    }

                    if(this.form.vat_account_fdvex) {
                        this.form.articles_vataccountfdvex = {
                            name: this.form.vat_account_fdvex.accountingaccount_number + " ("+ this.form.vat_account_fdvex.accountingaccount_label +")", 
                            id: this.form.vat_account_fdvex.accountingaccount_id
                        }
                    }

                    if(this.form.vat_account_fdvxp) {
                        this.form.articles_vataccountfdvxp = {
                            name: this.form.vat_account_fdvxp.accountingaccount_number + " ("+ this.form.vat_account_fdvxp.accountingaccount_label +")", 
                            id: this.form.vat_account_fdvxp.accountingaccount_id
                        }
                    }

                    if(this.form.articles_type) {
                        this.form.articles_articlestype = {
                            articlestype_label: this.getTrad(this.form.articles_type.articlestype_label),
                            articlestype_id: this.form.articles_type.articlestype_id
                        }
                    }

                    this.ajouter = false
                    this.modifier = true
                } else {
                    this.form = this.deppCloneObj(this.default_form)

                    if(this.preselect_infos && this.preselect_infos.accounting_account) {
                        const accounting = this.comptes_comptables.find(elem => elem.name.indexOf(this.preselect_infos.accounting_account) > -1)
                        if(accounting) {
                            this.form.articles_accountingaccount = accounting
                            this.form.articles_accountingaccountfdvex = accounting
                            this.form.articles_accountingaccountfdvxp = accounting
                        }
                    }

                    if(this.preselect_infos && this.preselect_infos.vat_value) {
                        this.form.articles_vat = this.vat.find(vat => vat.vat_value == this.preselect_infos.vat_value)
                        const accounting = this.comptes_comptables_vat.find(elem => elem.vat == this.form.articles_vat.vat_id)
                        if(accounting) {
                            this.form.articles_vataccount = accounting
                            this.form.articles_vataccountfdvex = accounting
                            this.form.articles_vataccountfdvxp = accounting
                        }
                        
                    }

                    if(this.preselect_infos) {
                        this.form.articles_label = this.preselect_infos.articles_label
                        this.correctCode()
                    }

                    this.ajouter = true
                    this.modifier = false
                }

                

                this.modal_loaded = true
            },
            checkForm: async function () {
                if(!this.processing) {

                    this.processing = true
                    this.form_message = ""
                    this.error = []

                    if(!this.form.articles_label) {
                        this.error.push("Erreur nom")
                    }

                    if(this.form.articles_ht === '') {
                        this.error.push("Erreur tarif")
                    }

                    if(!this.form.articles_code) {
                        this.error.push("Erreur code")
                    }

                    if(!this.form.articles_vat || Object.keys(this.form.articles_vat).length == 0) {
                        this.error.push("Erreur tva")
                    }

                    if(!this.form.articles_accountingaccount || Object.keys(this.form.articles_accountingaccount).length == 0) {
                        this.error.push("Erreur compte comptable")
                    }

                    if(!this.form.articles_accountingaccountfdvex || Object.keys(this.form.articles_accountingaccountfdvex).length == 0) {
                        this.error.push("Erreur compte comptable fdvex")
                    }

                    if(!this.form.articles_accountingaccountfdvxp || Object.keys(this.form.articles_accountingaccountfdvxp).length == 0) {
                        this.error.push("Erreur compte comptable fdvxp")
                    }

                    if(!this.form.articles_vataccount || Object.keys(this.form.articles_vataccount).length == 0) {
                        this.error.push("Erreur vat compte comptable")
                    }

                    if(!this.form.articles_vataccountfdvex || Object.keys(this.form.articles_vataccountfdvex).length == 0) {
                        this.error.push("Erreur vat compte comptable fdvex")
                    }

                    if(!this.form.articles_vataccountfdvxp || Object.keys(this.form.articles_vataccountfdvxp).length == 0) {
                        this.error.push("Erreur vat compte comptable fdvxp")
                    }

                    if(!this.form.articles_articlestype || Object.keys(this.form.articles_articlestype).length == 0) {
                        this.error.push("Erreur articles type")
                    }

                    if(this.error.length == 0) {
                        let result = null
                        const vat = this.vat.filter(vat => { return vat.vat_id == this.form.articles_vat.vat_id })
                        if(vat) {
                            const tva = parseFloat(vat[0].vat_value)
                            const ht = parseFloat(this.form.articles_ht.toString().replace(',', '.'))
                            let ttc = (vat[0].vat_value * ht) + ht
                            if(ttc.toString().indexOf(".") > -1){
                                ttc = ttc.toString().substr(0, ttc.toString().indexOf(".") + 3).toFloat()
                            }

                            if(this.form.articles_id) {
                                result = await this.editArticles(
                                    this.form.articles_id,
                                    this.form.articles_accountingaccount.id,
                                    this.form.articles_vat.vat_id,
                                    this.form.articles_label,
                                    this.form.articles_code,
                                    ht,
                                    ttc,
                                    false,
                                    this.form.articles_articlestype.articlestype_id,
                                    this.form.articles_accountingaccountfdvex.id,
                                    this.form.articles_accountingaccountfdvxp.id,
                                    this.form.articles_vataccount.id,
                                    this.form.articles_vataccountfdvex.id,
                                    this.form.articles_vataccountfdvxp.id,
                                    this.form.articles_doublons,
                                    this.form.articles_quantite,
                                    this.form.articles_commentaire
                                );
                            } else {
                                result = await this.addArticles(
                                    this.form.articles_accountingaccount.id,
                                    this.form.articles_vat.vat_id,
                                    this.form.articles_label,
                                    this.form.articles_code,
                                    ht,
                                    ttc,
                                    this.form.articles_articlestype.articlestype_id,
                                    this.form.articles_accountingaccountfdvex.id,
                                    this.form.articles_accountingaccountfdvxp.id,
                                    this.form.articles_vataccount.id,
                                    this.form.articles_vataccountfdvex.id,
                                    this.form.articles_vataccountfdvxp.id,
                                    this.form.articles_doublons,
                                    this.form.articles_quantite,
                                    this.form.articles_commentaire
                                );
                            }
                        }

                        this.processing = false

                        if(result) {
                            if(typeof this.$parent.loadArticlesListe == "function"){
                                this.$parent.loadArticlesListe()
                                this.$refs['modal'].hide()
                                this.$emit('submit')
                            }

                            this.$emit('article_added', result.retour.articles_id)

                        } else {
                            this.form_message = "error.LEP"
                        }
                    } else {
                        this.form_message = "formulaire.erreur_champs_non_remplis"
                        this.processing = false
                    }
                }
            },
            correctCode()
            {
                if(this.form.articles_id == "" && this.form.articles_code == "")
                {
                    this.form.articles_code = this.accentsTidy(this.form.articles_label).replace(/ /g, "_")
                }
            },
            async setHtArticle(ht){
				ht = ht.replace(",", ".").replace(/\s/g, '')

                this.form.articles_ht = await this.priceFormat(ht)
            }
        },
        watch: {
            accountingplan_id(val){
                this.accountingplan_id_local = val
            },
            'form.articles_accountingaccount'(val){
                if(this.form.articles_accountingaccountfdvxp == null || Object.keys(this.form.articles_accountingaccountfdvxp).length == 0)
                {
                    this.form.articles_accountingaccountfdvxp = val
                }

                if(this.form.articles_accountingaccountfdvex == null || Object.keys(this.form.articles_accountingaccountfdvex).length == 0)
                {
                    this.form.articles_accountingaccountfdvex = val
                }
            },
            'form.articles_vataccount'(val){
                if(this.form.articles_vataccountfdvxp == null || Object.keys(this.form.articles_vataccountfdvxp).length == 0)
                {
                    this.form.articles_vataccountfdvxp = val
                }

                if(this.form.articles_vataccountfdvex == null || Object.keys(this.form.articles_vataccountfdvex).length == 0)
                {
                    this.form.articles_vataccountfdvex = val
                }
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') ,
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput')
        }
    }
</script>
